<template>
  <div>
    <h2 class="text-2xl font-semibold mb-4">Portfolio</h2>

    <!-- Other Projects -->
    <div>
      <h3 class="text-xl font-semibold mb-4">Some Live Projects</h3>
      <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div
          v-for="project in otherProjects"
          :key="project.title"
          class="bg-white/10 rounded-lg overflow-hidden hover:bg-white/15 transition-all duration-300"
        >
          <div class="relative group">
            <img
              :src="project.img"
              :alt="project.title"
              class="w-full h-48 object-cover"
            />
            <div
              class="absolute inset-0 bg-black bg-opacity-50 opacity-0 group-hover:opacity-100 transition-opacity duration-300 flex items-center justify-center"
            >
              <div class="flex space-x-3">
                <a
                  :href="project.liveUrl"
                  target="_blank"
                  class="bg-white text-gray-900 p-2 rounded-full hover:bg-gray-100 transition-colors duration-200"
                  title="View Live Demo"
                >
                  <Eye class="h-5 w-5" />
                </a>
              </div>
            </div>
          </div>
          <div class="p-4">
            <h4 class="text-lg font-semibold mb-2">{{ project.title }}</h4>
            <p class="text-gray-300 text-sm mb-3">{{ project.desc }}</p>
            <div class="flex flex-wrap gap-2">
              <span
                v-for="tag in project.tags"
                :key="tag"
                class="px-2 py-1 bg-white/20 rounded-full text-xs"
              >
                {{ tag }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { Eye } from "lucide-vue-next";
import { portfolioProjects } from "../../data/portfolioProjects";

const featuredProjects = computed(() =>
  portfolioProjects.filter((project) => project.featured)
);

const otherProjects = computed(() =>
  portfolioProjects.filter((project) => !project.featured)
);
</script>

<style scoped>
.group:hover img {
  transform: scale(1.05);
  transition: transform 0.3s ease;
}

img {
  transition: transform 0.3s ease;
}
</style>
