<template>
  <div
    class="min-h-screen bg-gradient-to-br from-[#ff8fa3] via-[#5a189a] to-[#e5989b] text-[#f8f9fa] p-4 sm:p-8"
  >
    <div
      class="w-full max-w-4xl mx-auto bg-white/10 backdrop-blur-lg text-white shadow-2xl rounded-lg"
    >
      <div class="p-6">
        <ProfileHeader />
        <div class="w-full">
          <TabNavigation
            :tabs="tabs"
            :activeTab="activeTab"
            @update:tab="setActiveTab"
          />
          <transition name="fade-slide" mode="out-in">
            <div :key="activeTab" class="text-gray-100">
              <AboutTab v-if="activeTab === 'about'" />
              <ResumeTab v-else-if="activeTab === 'resume'" />
              <PortfolioTab v-else-if="activeTab === 'portfolio'" />
              <BlogTab
                v-else-if="activeTab === 'blog'"
                @open-post="openBlogPost"
              />
            </div>
          </transition>
        </div>
      </div>
    </div>

    <BlogPostModal
      v-if="selectedPost"
      :post="selectedPost"
      @close="closeModal"
    />
  </div>
</template>

<script setup>
import { ref } from "vue";
import ProfileHeader from "./components/layout/ProfileHeader.vue";
import TabNavigation from "./components/TabNavigation.vue";
import AboutTab from "./components/tabs/AboutTab.vue";
import ResumeTab from "./components/tabs/ResumeTab.vue";
import PortfolioTab from "./components/tabs/PortfolioTab.vue";
import BlogTab from "./components/tabs/BlogTab.vue";
import BlogPostModal from "./components/shared/BlogPostModal.vue";
import { tabs } from "./data/tabs";
import { useBlog } from "./composables/useBlog";

const activeTab = ref("about");
const { selectedPost, openBlogPost, closeModal } = useBlog();

const setActiveTab = (tab) => {
  activeTab.value = tab;
};
</script>

<style scoped>
.fade-slide-enter-active,
.fade-slide-leave-active {
  transition: all 0.3s ease;
}

.fade-slide-enter-from,
.fade-slide-leave-to {
  opacity: 0;
  transform: translateY(20px);
}

.fade-slide-enter-to,
.fade-slide-leave-from {
  opacity: 1;
  transform: translateY(0);
}
</style>
