export const workExperience = [
  {
    title: "IT Project Manager",
    company: "Ventup",
    period: "2022 - Present",
    responsibilities: [
      "Led and managed the SaaS project to align with business objectives, coordinating diverse teams to foster collaboration and accountability for milestone achievement.",
      "Oversaw deployments, created detailed documentation, conducted risk assessments, and proactively removed impediments to ensure smooth project progression and delivery.",
    ],
  },
  {
    title: "Product Manager",
    company: "Tango",
    period: "2021 - 2022",
    responsibilities: [
      "Managed product planning and execution of startups, overseeing the product lifecycle, prioritizing product and customer requirements.",
      "Developed product roadmaps and promoted strong collaboration with engineering and cross-functional teams to ensure alignment with customer satisfaction goals.",
    ],
  },
];
