export const portfolioProjects = [
  {
    title: "PM resources",
    desc: "I've developed a reference website using Astro that houses my frequently accessed project management documentation.",
    tags: ["Astro"],
    liveUrl: "https://pm-docs-nine.vercel.app/",
    img: "/project/resources.jpg",
  },
  {
    title: "Alien Password Gen",
    desc: "A simple password generator with an extraterrestrial twist.",
    tags: ["Python", "Streamlit"],
    liveUrl: "https://alienpassgenerator.streamlit.app/",
    img: "/project/PassGen.jpg",
  },
  {
    title: "URL Shortener",
    desc: "This is a basic url shortener similar to bitly but without the stats. The good part about it is that nothing is kept on any server.",
    tags: ["HTML", "CSS", "Javascript"],
    liveUrl: "https://short-url-5wm38.ondigitalocean.app/",
    img: "/project/url.jpg",
  },
  {
    title: "Text to speech",
    desc: "A simple app that reads everything you type. You may choose from any of the various voices, and the best part is that it's free, with no constraints, and nothing is saved.",
    tags: ["HTML", "CSS", "Javascript"],
    liveUrl: "https://speechio-rahox.ondigitalocean.app/",
    img: "/project/speechio.jpg",
  },
  {
    title: "Alien Ipsum Gen",
    desc: "A react-based with an extraterrestrial twist.",
    tags: ["React"],
    liveUrl: "https://alienipsum.vercel.app/",
    img: "/project/alienipsum.jpg",
  },
  {
    title: "Burndown Chart",
    desc: "Users can input the sprint start and end dates along with daily story points to visualize the actual progress against the expected burndown.",
    tags: ["Python"],
    liveUrl: "https://bdc-gen-ncid7.ondigitalocean.app/",
    img: "/project/BDC.jpg",
  },
];
