<template>
  <div>
    <h2 class="text-2xl font-semibold mb-4">Resume</h2>
    <div class="mb-6">
      <h3 class="text-xl font-semibold mb-2">Work Experience</h3>
      <div
        v-for="(job, index) in workExperience"
        :key="index"
        class="mb-4 bg-white/10 p-4 rounded-lg"
      >
        <h4 class="text-lg font-medium">{{ job.title }} - {{ job.company }}</h4>
        <p class="text-sm text-gray-300">{{ job.period }}</p>
        <ul class="list-disc list-inside mt-2 space-y-1">
          <li v-for="(responsibility, i) in job.responsibilities" :key="i">
            {{ responsibility }}
          </li>
        </ul>
      </div>
      <p>To check the rest of my work experience download my CV:</p>
      <button
        @click="downloadCV"
        class="mt-4"
        style="
          background-color: #ef476f;
          color: white;
          padding: 0.5rem 1rem;
          border-radius: 0.375rem;
        "
      >
        Download CV
      </button>
    </div>
    <div>
      <h3 class="text-xl font-semibold mb-2">Education</h3>
      <div class="bg-white/10 p-4 rounded-lg">
        <h4 class="text-lg font-medium">
          BS in Computer Science - Instituto Tecnológico de Colima
        </h4>
        <h6>Major in web technologies.</h6>
        <p class="text-sm text-gray-300">2007 - 2012</p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { workExperience } from "../../data/workExperience";

const downloadCV = () => {
  const link = document.createElement("a");
  link.href = "/TereDiazCV.pdf";
  link.download = "terediazcv.pdf";
  link.click();
};
</script>
