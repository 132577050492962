import { ref } from "vue";

export function useBlog() {
  const selectedPost = ref(null);

  const openBlogPost = (post) => {
    selectedPost.value = post;
  };

  const closeModal = () => {
    selectedPost.value = null;
  };

  return {
    selectedPost,
    openBlogPost,
    closeModal,
  };
}
